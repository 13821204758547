import apiTool from "./index";
import apiService from "./ApiService";


const LOGIN_URL = "auser/connexion/";
const LOGIN_SSO_URL = "auser/connexion-sso/";
const REGISTER_URL = "auser/process-registration/";
const GET_USER_URL = "auser/get-user";
const FORGET_PASSWORD_URL = "auser/forgot-password/";
const CHANGE_PASSWORD_URL = "auser/change-password/";

export default {
    login(payload) {
        return apiTool.post(LOGIN_URL, payload);
    },
    loginSso(token) {
        apiService.setHeader(); // Set the authentication header
        const urlWithToken = `${LOGIN_SSO_URL}?Authorization=${token}`;
        // console.log(urlWithToken)
        return apiTool.get(urlWithToken);
    },
    getUser() {
        return apiService.get(GET_USER_URL).then(response => {
            return response.data;
        })
    },
    register(payload) {
        return apiTool.post(REGISTER_URL, payload);
    },
    forgetPassword(payload) {
        return apiTool.post(FORGET_PASSWORD_URL, payload);
    },
    changePassword(payload) {
        return apiTool.post(CHANGE_PASSWORD_URL, payload);
    },
};
