import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SettingsEnum";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import settingsApi from "../../core/services/Settings";

export interface Settings {
    clientName: string;
    clientLogo: string;
    clientSignature: string;
    groupParticipationLink: string;
    is_activated: boolean;
    is_sso_activated: boolean;
    fr: boolean;
    en: boolean;
}

export interface SettingsInfo {
    settingsData: Settings;
}

@Module
export default class SettingsModule extends VuexModule implements SettingsInfo {
    settingsData = {
        clientName: "",
        clientLogo: "",
        clientSignature: "",
        groupParticipationLink: "",
        is_activated: true,
        is_sso_activated: true,
        fr: true,
        en: true,
    };
    /**
     * Get current user object
     * @returns Settings
     */
    get currentSettings(): Settings {
        return this.settingsData;
    }

    @Mutation
    [Mutations.SET_SETTINGS](settings) {
        this.settingsData = settings;
    }

    @Action
    [Actions.UPDATE_SETTINGS](settings) {
        return new Promise<void>((resolve, reject) => {
            settingsApi
                .updateSettings(settings)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_SETTINGS, data);
                    resolve();
                })
                .catch(({ error }) => {
                    reject(error);
                });
        });
    }

    @Action
    [Actions.GET_SETTINGS]() {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            settingsApi
                .getSettings()
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_SETTINGS, data);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }
}
