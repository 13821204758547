import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { msalInstance } from "@/core/config/AuthConfig";
import { PublicClientApplication } from "@azure/msal-browser";

import authApi from "../../core/services/Authenticate";

export interface UserProfile {
    user_type: any;
}

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    token: string;
    userprofile: UserProfile;
}

export interface UserModified {
    user: User;
    services: Array<string>;
}

export interface UserAuthInfo {
    errors: Array<string>;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = [];
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();

    /**
     * Get current user object
     * @returns UserModified
     */
    get currentUser(): UserModified {
        let services = [];
        if (this.isAuthenticated && this.user.userprofile) {
            services = this.user.userprofile.user_type.services.map((service) => {
                return service.slug;
            });
        }
        return {
            user: this.user,
            services: services,
        };
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = [];
        // if (store.getters.currentSettings.is_sso_activated) {
            // console.log("saving user token")
            JwtService.saveToken(this.user.token);
        // }
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
    }

    @Action
    [Actions.LOGIN](credentials) {
        return new Promise<void>((resolve, reject) => {
            authApi
                .login(credentials)
                .then(({ data }) => {
                    // console.log("data login")
                    // console.log(data)
                    if (!data.success) {
                        this.context.commit(Mutations.SET_ERROR, data.error);
                        reject();
                    } else {
                        this.context.commit(Mutations.SET_AUTH, data.data);
                        resolve();
                    }
                })
                .catch(({ error }) => {
                    this.context.commit(Mutations.SET_ERROR, error);
                    reject(error);
                });
        });
    }

    @Action
    [Actions.LOGIN_SSO](token) {
        // console.log("LOGIN SSO START")
        return new Promise<void>((resolve, reject) => {
            authApi
                .loginSso(token)
                .then(({ data }) => {
                    // console.log("data login sso")
                    // console.log(data)
                    if (data.token) {
                        this.context.commit(Mutations.SET_AUTH, data);
                        resolve();
                    } else {
                        reject()
                    }
                })
                .catch(({ error }) => {
                    // console.log("LOGIN ERROR")
                    this.context.commit(Mutations.SET_ERROR, error);
                    reject(error);
                });
        }
        );
    }

    // @Action
    // [Actions.LOGIN_SSO](credentials) {
    //     return new Promise<void>((resolve, reject) => {
    //         authApi
    //             .loginSso(credentials)
    //             .then(({ data }) => {
    //                 if (!data.success) {
    //                     this.context.commit(Mutations.SET_ERROR, data.error);
    //                     reject();
    //                 } else {
    //                     this.context.commit(Mutations.SET_AUTH, data.data);
    //                     resolve();
    //                 }
    //             })
    //             .catch(({ error }) => {
    //                 this.context.commit(Mutations.SET_ERROR, error);
    //                 reject(error);
    //             });
    //     });
    // }

    @Action
    [Actions.LOGOUT]() {
        if (store.getters.currentSettings.is_sso_activated) {
            // console.log("logout SSO")
            const accounts = msalInstance.getAllAccounts(); // Retrieve all signed-in accounts

            if (accounts.length > 0) {
                const homeAccountId = accounts[0].homeAccountId;
                // console.log("Home Account ID:", homeAccountId);

                const logoutRequest = {
                    account: msalInstance.getAccountByHomeId(homeAccountId),
                };

                try {
                    msalInstance.logoutRedirect(logoutRequest);
                    // console.log("Logout successful");
                } catch (error) {
                    console.error("Could not perform action logout", error);
                }
            }
        }


        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("registration", credentials)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return new Promise<void>((resolve, reject) => {
            authApi
                .forgetPassword(payload)
                .then(({ data }) => {
                    if (!data.success) {
                        this.context.commit(Mutations.SET_ERROR, data.error);
                        reject();
                    } else {
                        resolve();
                    }
                })
                .catch(({ error }) => {
                    this.context.commit(Mutations.SET_ERROR, error);
                    reject(error);
                });
        });
    }

    @Action
    [Actions.CHANGE_PASSWORD](payload) {
        return new Promise<void>((resolve, reject) => {
            authApi
                .changePassword(payload)
                .then(({ data }) => {
                    if (!data.success) {
                        this.context.commit(Mutations.SET_ERROR, data.error);
                        reject();
                    } else {
                        resolve();
                    }
                })
                .catch(({ error }) => {
                    this.context.commit(Mutations.SET_ERROR, error);
                    reject(error);
                });
        });
    }

    @Action
    [Actions.VERIFY_AUTH]() {
        return new Promise<void>((resolve, reject) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                resolve();
            } else {
                this.context.commit(Mutations.PURGE_AUTH);
                reject();
            }
        })
    }

    @Action
    [Actions.UPDATE_USER](payload) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.post("update_user", payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_USER, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.GET_USER]() {
        if (this.isAuthenticated) {
            // console.log("im in get_user")
            // console.log(this.isAuthenticated)
            // console.log(JwtService.getToken())
            ApiService.setHeader();
            return new Promise<void>((resolve, reject) => {
                authApi
                    .getUser()
                    .then(({ data }) => {
                        // console.log("User set correctly")
                        this.context.commit(Mutations.SET_USER, data.user);
                        resolve();
                    })
                    .catch(({ response }) => {
                        // console.log("Failed to get user")
                        this.context.commit(Mutations.PURGE_AUTH);
                        reject();
                    });
            });
        }
    }
}
