import { Locales } from "./locales";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const en = require("./en.json");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const fr = require("./fr.json");

export const messages = {
    [Locales.FR]: fr,
    [Locales.EN]: en
};

export const defaultLocale = Locales.FR;
