import apiTool from "./index";

const UPDATE_SETTINGS_URL = "settings/update/";
const GET_SETTINGS_URL = "settings/get/";
const GET_GROUPREPORT_URL = "settings/get-group-file/";

export default {
    getSettings() {
        return apiTool.get(GET_SETTINGS_URL);
    },
    getGroupReport(payload) {
        return apiTool.post(GET_GROUPREPORT_URL, payload).then(response => {
            return response.data;
        });
    },
    updateSettings(payload) {
        return apiTool.post(UPDATE_SETTINGS_URL, payload);
    },
};
