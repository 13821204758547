
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Actions as ActionsSettings } from "@/store/enums/SettingsEnum";

export default defineComponent({
    name: "app",
    setup() {
        const store = useStore();
        onMounted(() => {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.dispatch(ActionsSettings.GET_SETTINGS);
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
        });
    },
});
