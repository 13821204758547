enum Actions {
    GET_SETTINGS = "getSettings",
    UPDATE_SETTINGS = "updateSettings",
}

enum Mutations {
    // mutation types
    SET_SETTINGS = "setSettings",
}

export { Actions, Mutations };
